import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { useInView } from 'react-intersection-observer';
import cn from 'classnames';
import styles from '../../styles/forthBlock.module.css';

const Forth = ({ onRef }) => {
  const [ref, inView, entry] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const images = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "workflow.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "collaborate.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "smart.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container} ref={onRef}>
        <div className={styles.group}>
          <div className={styles.leftContentContainer}>
            <h2 className={styles.title}>Workflow Automation</h2>
            <p className={styles.text}>Configure the entire lifecycle of your process once and let Clarity Value handle the legwork.  Our platform lets you configure application intake and approval, fee calculation and collection, scheduling and performing inspections, which reduces staffing costs and increases productivity.</p>
          </div>
            
          <div className={styles.imageContainer}>
            <Img
              fluid={images.image1.childImageSharp.fluid}
              alt="workflow"
              className={styles.imageLeftRadius}
              fadeIn={true}
            />
          </div>
        </div>

        <div className={styles.thecondGroup}>
          <div className={styles.imageContainer}>
            <Img
              fluid={images.image2.childImageSharp.fluid}
              alt="collaborate"
              className={styles.imageRightRadius}
              fadeIn={true}
            />
          </div>

          <div className={styles.rightContentContainer}>
            <h2 className={styles.title}>Orchestrate and Collaborate</h2>
            <p className={styles.text}>Configure processes that span multiple departments or cities.  Cities can hook into a process without leaving their own systems.</p>
          </div>
        </div>

        <div className={styles.group}>
          <div className={styles.leftContentContainer}>
            <h2 className={styles.title}>Smart AI Assistant</h2>
            <p className={styles.text}>Our smart AI assistant goes beyond workflow automation to help.  Provide intelligent suggestions for your reviewers and help them query your codes and records.  The more data you give our assistant, the more useful it becomes.</p>
          </div>

          <div className={styles.imageContainer}>
            <Img
              fluid={images.image3.childImageSharp.fluid}
              alt="smart"
              className={styles.imageLeftRadius}
              fadeIn={true}
            />
          </div>
        </div>

      </div>

    </div>
  );
};

export default Forth;
