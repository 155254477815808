import React from 'react';
import Details from './details';
import { useStaticQuery, graphql } from 'gatsby'; 
import Img from 'gatsby-image';
import styles from '../../styles/thirdBlock.module.css';

const Third = ({ onRef }) => {
  const images = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "system1.png" }) {
        childImageSharp {
          fluid(maxWidth: 480) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "system2.png" }) {
        childImageSharp {
          fluid(maxWidth: 480) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div>
      <Details/>
      <div className={styles.wrapper}>
        <div className={styles.container} ref={onRef}>
          <div className={styles.contentContainer}>
            <h1 className={styles.title}>One system, one place</h1>
            <p className={styles.description}>Streamline back office processes, improve digital services, and get a unified view of your administrative activity - all in the cloud.</p>
          </div>
          <div className={styles.imagesContainer}>
            <div className={styles.imageContainer}>
              <Img
                fluid={images.image1.childImageSharp.fluid}
                alt="system_1"
                className={styles.image}
                fadeIn={true}
              />
            </div>
            <div className={styles.imageContainer}>
              <Img
                fluid={images.image2.childImageSharp.fluid}
                alt="system_2"
                className={styles.image}
                fadeIn={true}
              />
            </div>
         </div>
        </div>
      </div>
    </div>
  ); 
};

export default Third;
