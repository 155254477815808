import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styles from '../styles/socials.module.css';

import GoogleIcon from '../assets/icons/google-icon.svg';
import TwitterIcon from '../assets/icons/twitter-icon.svg';
import FacebookIcon from '../assets/icons/facebook-icon.svg';

const Socials = () => {
  const images = useStaticQuery(graphql`
    query {
      facebook: file(relativePath: { eq: "facebook.png" }) {
        childImageSharp {
          fixed(height: 20, width: 20) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      google: file(relativePath: { eq: "google.png" }) {
        childImageSharp {
          fixed(height: 20, width: 20) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      twitter: file(relativePath: { eq: "twitter.png" }) {
        childImageSharp {
          fixed(height: 20, width: 20) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <div className={styles.container}>
    </div>
  );
};

export default Socials;