import React, { useRef, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styles from '../../styles/firstBlock.module.css';
import SheduleDemoModal from '../../components/ScheduleDemoModal';
import CVvideo from '../../assets/videos/cv.mp4';


const First = ({showModal, handleModal}) => {
  const [playState, setState] = useState(false);
  const handleOpenModal = () => handleModal(true)
  const handleCloseModal = () => handleModal(false)

  const videoRef = useRef(null);
  const toggleVideo = () => {
    playState ? videoRef.current.pause() : videoRef.current.play();
    setState(!playState);
  };

  const images = useStaticQuery(graphql`
    query {
      playBtn: file(relativePath: { eq: "play.png" }) {
        childImageSharp {
          fixed(height: 20) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      videoPreview: file(relativePath: { eq: "videoPreview.png" }) {
        childImageSharp {
          fluid(maxHeight: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className={styles.wrapper}>
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <h1 className={styles.title}>The public sector is evolving.</h1>
        <p className={styles.description}>Move securely to the cloud and manage your workflows from one powerful and modern solution.</p>
        <button className={styles.button} onClick={handleOpenModal}>
          <span>
            Schedule demo
          </span>
        </button>
        <SheduleDemoModal
          showModal={showModal}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
        />
      </div>

      <div className={styles.playBtnContainer}>
        { playState ?
          null :
          <Img
            fixed={images.playBtn.childImageSharp.fixed}
            alt="play"
            className={styles.playBtn}
            fadeIn={true}
          />
        }
      </div>

      <div className={styles.videoContainer} onClick={toggleVideo}>
        <video controls={false} ref={videoRef} poster={images.videoPreview.childImageSharp.fluid.src}>
          <source src={CVvideo} type="video/mp4"/>
        </video>
      </div>
    </div>
    </div>
  );
}

export default First;
