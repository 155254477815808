import React from 'react';
import styles from '../styles/logo.module.css';

const Logo = ({ color, children }) => (
  <div
    className={styles.logo}
    style={{ color }}
  >
    {children}
    <p className={styles.title}>ClarityValue</p>
  </div>
);

export default Logo;