import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import AccessibilityIcon from '../../assets/icons/accessibility-icon.svg';
import GisIntegrationIcon from '../../assets/icons/gis-integration-icon.svg';
import AnalyticsIcon from '../../assets/icons/analytics-icon.svg';
import CitizenSelfServiceIcon from '../../assets/icons/citizen-self-service-icon.svg';
import CloudPlatformIcon from '../../assets/icons/cloud-platform-icon.svg';
import ProjectExplorerIcon from '../../assets/icons/project-explorer-icon.svg';
import CollaborationAndProcessSharingIcon from '../../assets/icons/collaboration-and-process-sharing-icon.svg';
import RulesAndWorkflowAutomationIcon from '../../assets/icons/rules-and-workflow-automation-icon.svg';
import FormBuilderAndIntelligentFormsIcon from '../../assets/icons/form-builder-and-intelligent-forms-icon.svg';
import ArrowIcon from '../../assets/icons/arrow-icon.svg';

import styles from '../../styles/details.module.css';

const First = () => {
  const [viewMore, handleViewMore] = useState(false)

  const images = useStaticQuery(graphql`
    query {
      detailsArrow: file(relativePath: { eq: "detailsArrow.png" }) {
        childImageSharp {
          fluid(maxWidth: 20, maxHeight: 20) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.group}>
          <div className={styles.col}>
            <div className={styles.iconContainer}>
              <RulesAndWorkflowAutomationIcon />
            </div>
            <h1 className={styles.title}>Rules and workflow automation</h1>
            <p className={styles.description}>
              Easily configure and manage business rules for an unlimited number of workflows- all without having to write a single line of code.
            </p>
          </div>
          <div className={styles.col}>
            <div className={styles.iconContainer}>
              <FormBuilderAndIntelligentFormsIcon />
            </div>
            <h1 className={styles.title}>Form builder and intelligent forms</h1>
            <p className={styles.description}>
              Digitize your existing forms with our intuitive drag-and-drop form builder.  Spend less time and money managing paperwork.
            </p>
          </div>
          <div className={styles.col}>
            <div className={styles.iconContainer}>
              <GisIntegrationIcon />
            </div>
            <h1 className={styles.title}>GIS integration</h1>
            <p className={styles.description}>
              Clarity Value integrates with your existing GIS systems, including ArcGIS.  Incorporate your GIS data into your rules and workflows- no code required.
            </p>
          </div>
        </div>
        
        { viewMore ?
          (
            <div>
              <div className={styles.group}>
                <div className={styles.col}>
                  <div className={styles.iconContainer}>
                    <AccessibilityIcon />
                  </div>
                  <h1 className={styles.title}>Accessibility</h1>
                  <p className={styles.description}>
                    Clarity Value was built from the start with accessibility as a top priority and is ADA compliant.  Ensure that all of your citizens can get what they need done.
                  </p>
                </div>
                <div className={styles.col}>
                  <div className={styles.iconContainer}>
                    <CitizenSelfServiceIcon />
                  </div>
                  <h1 className={styles.title}>Citizen self-service</h1>
                  <p className={styles.description}>
                    Easily configure a modern digital portal where citizens can manage their projects, applications and licenses without having to drop in to the office.
                  </p>
                </div>
                <div className={styles.col}>
                  <div className={styles.iconContainer}>
                    <AnalyticsIcon />
                  </div>
                  <h1 className={styles.title}>Analytics</h1>
                  <p className={styles.description}>
                    View quick updates in a customizable dashboard and prepare reports with a single click.  Drop down to our full featured analytics engine for a deeper understanding of your process bottlenecks.
                  </p>
                </div>
              </div>
              <div className={styles.group}>
                <div className={styles.col}>
                  <div className={styles.iconContainer}>
                    <CloudPlatformIcon />
                  </div>
                  <h1 className={styles.title}>Cloud platform</h1>
                  <p className={styles.description}>
                    Clarity Value runs on modern, industry-leading cloud services so you don't have to lose sleep over maintaining your own servers and securing them.  But we make on-premise deployments easy too.
                  </p>
                </div>
                <div className={styles.col}>
                  <div className={styles.iconContainer}>
                    <ProjectExplorerIcon />
                  </div>
                  <h1 className={styles.title}>Project explorer</h1>
                  <p className={styles.description}>
                    Full featured, map-based explorer lets citizens, government officials and policy researchers alike efficiently find the information they need for their projects.
                  </p>
                </div>
                <div className={styles.col}>
                  <div className={styles.iconContainer}>
                    <CollaborationAndProcessSharingIcon />
                  </div>
                  <h1 className={styles.title}>Collaboration and process sharing</h1>
                  <p className={styles.description}>
                    Multiple stakeholders can collaborate on workflows frictionlessly and designate ownership for accountability.  Share processes to expedite adoption of what works better.
                  </p>
                </div>
              </div>
            
            <div
              onClick={() => handleViewMore(false)}
              className={styles.showLess}
            >
              <p className={styles.linkText}>Show Less</p>
              <div className={styles.arrowContainer}>
                <ArrowIcon className={styles.arrow}/>
              </div>
            </div>
            </div>
          ) :
          (
            <div
              onClick={() => handleViewMore(true)}
              className={styles.viewMore}
            >
              <p className={styles.linkText}>Show more</p>
              <div className={styles.arrowContainer}>
                <ArrowIcon className={styles.arrow}/>
              </div>
            </div>
          )}
      </div>
    </div>
  );
}

export default First;
