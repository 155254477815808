import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styles from '../../styles/secondBlock.module.css';

const Second = ({ onRef }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "phone.png" }) {
        childImageSharp {
          fixed(height: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container} ref={onRef}>
        <div className={styles.phoneContainer}>
          <Img
          fixed={data.placeholderImage.childImageSharp.fixed}
            alt="phone"
            className={styles.phone}
            fadeIn={true}
          />
        </div>
          <div className={styles.contentContainer}>
          <h1 className={styles.title}>Build digital services to delight your citizens</h1>
          <p className={styles.description}>From event permitting to business licensing to code enforcement, our platform makes it easy to digitize your services.  Effortlessly deploy them to intuitive web and mobile portals that will delight your citizens.</p>
        </div>
      </div>
    </div>
  );
};

export default Second;
