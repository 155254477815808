import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import BurgerIcon from '../assets/icons/burger-icon.svg'
import Logo from '../components/logo';
import BurgerMenu from '../components/BurgerMenu';
import styles from '../styles/header.module.css';

const Header = ({ onRef, scrollToPlatform, scrollToFeatures, scrollToProcess, handleModal }) => {
  const [showBurgerMenu, handleBurgerMenu] = useState(false);
  const handleOpenModal = () => handleModal(true)

  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo-blue.png" }) {
        childImageSharp {
          fluid(maxHeight: 45) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <header className={styles.wrapper} ref={onRef}>
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <Logo color="#654CFA">
            <Img
              fluid={data.placeholderImage.childImageSharp.fluid}
              alt="logo"
              fadeIn={true}
              className={styles.logo}
            />
          </Logo>
        </div>
    
        <div className={styles.burgerIconContainer}>
          <BurgerIcon onClick={() => handleBurgerMenu(true)}/>
        </div>
    
        <BurgerMenu
          scrollToPlatform={() => {scrollToPlatform(); handleBurgerMenu(false) }}
          scrollToFeatures={scrollToFeatures}
          scrollToProcess={scrollToProcess}
          handleOpenModal={handleOpenModal}
          showBurgerMenu={showBurgerMenu}
          handleBurgerMenu={handleBurgerMenu}
        />

        <div className={styles.linkContainer}>
          <button className={styles.link} onClick={scrollToPlatform}>Digital Services</button>
          <button className={styles.link} onClick={scrollToFeatures}>Product Features</button>
          <button className={styles.link} onClick={scrollToProcess}>Government Solutions</button>
          <button className={styles.scheduleBtn} onClick={handleOpenModal}>Schedule demo</button>
        </div>

      </div>
    </header>
  );
};

export default Header
