import React from 'react';
import Modal from 'react-modal';
import styles from '../styles/burger-menu.module.css'
import Cross from '../assets/icons/burger-menu-cross-icon.svg'


const moadlContent = {
  borderStyle: 'none',
  position: 'absolute',
  left: '0px',
  top: '0px',
  padding: '0',
  width: '100%',
  height: '100%',
  background: '#1C1C1C',
  borderRadius: 0,
}

const modalOverlay = {
  zIndex: 100,
}

const chainExecute = (first, second) => () => {
  new Promise(res => {
    first()
    res(true)
  }).then(() => second())
}

const BurgerMenu = ({ scrollToPlatform, scrollToFeatures, scrollToProcess, handleOpenModal, showBurgerMenu, handleBurgerMenu }) => {

  const handleCloseBurgerMenu = () => handleBurgerMenu(false)

  return (
      <Modal
        isOpen={showBurgerMenu}
        style={{ overlay: modalOverlay, content: moadlContent }}
        onRequestClose={handleCloseBurgerMenu}
      >

        <div className={styles.crossIconContainer}>
          <Cross
            onClick={handleCloseBurgerMenu}
          />
        </div>

        <div className={styles.linkContainer}>
          <button className={styles.link} onClick={ chainExecute(handleCloseBurgerMenu, scrollToPlatform) }>Digital Services</button>
          <button className={styles.link} onClick={ chainExecute(handleCloseBurgerMenu, scrollToFeatures) }>Product Features</button>
          <button className={styles.link} onClick={ chainExecute(handleCloseBurgerMenu, scrollToProcess) }>Government Solutions</button>
          <button className={styles.scheduleBtn} onClick={ chainExecute(handleCloseBurgerMenu, handleOpenModal) }>Schedule a demo</button>
        </div>

      </Modal>
  );
}

export default BurgerMenu;
