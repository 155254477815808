import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Socials from '../components/socials';
import Logo from '../components/logo';
import styles from '../styles/footer.module.css';

const Footer = ({ scrollToHome, scrollToPlatform, scrollToFeatures, scrollToProcess }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo-grey.png" }) {
        childImageSharp {
          fixed(height: 45) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <footer className={styles.container}>

      <div className={styles.content}>
        <Logo color="#C7CBCF">
          <Img
            fixed={data.placeholderImage.childImageSharp.fixed}
            alt="logo"
            fadeIn={true}
          />
        </Logo>

        <div className={styles.links}>
          <button className={styles.link} onClick={scrollToHome}>Home</button>
          <button className={styles.link} onClick={scrollToPlatform}>Digital Services</button>
          <button className={styles.link} onClick={scrollToFeatures}>Product Features</button>
          <button className={styles.link} onClick={scrollToProcess}>Government Solutions</button>
        </div>
        <Socials/>
      </div>
      <div className={styles.copyright}>
        © {new Date().getFullYear()}, All rights reserved
      </div>


      <div className={styles.mobileContainer}>
        <Logo color="#C7CBCF">
            <Img
              fixed={data.placeholderImage.childImageSharp.fixed}
              alt="logo"
              fadeIn={true}
            />
          </Logo>
        <div className={styles.mobileContent}>
          <div className={styles.flexBlock}>
            <div className={styles.links}>
              <Link to="/" className={styles.link}>Home</Link>
              <button className={styles.link} onClick={scrollToPlatform}>Digital Services</button>
              <button className={styles.link} onClick={scrollToFeatures}>Product Features</button>
              <button className={styles.link} onClick={scrollToProcess}>Government Solutions</button>
            </div>
            <div className={styles.socialsBlock}>
              <Socials/>
              <div className={styles.copyright}>
                © {new Date().getFullYear()}, All rights reserved
              </div>
            </div>
          </div>
        </div>
      </div>

    </footer>
  );
};

export default Footer;
