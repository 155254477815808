import React, { useRef, useState } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Header from '../components/header';
import Footer from '../components/footer';

import FirstBlock from '../components/blocks/first';
import SecondBlock from '../components/blocks/second';
import ThirdBlock from '../components/blocks/third';
import ForthBlock from '../components/blocks/forth';

const IndexPage = () => {
  const [showModal, handleModal] = useState(false);
  const platform = useRef(null);
  const features = useRef(null);
  const process = useRef(null);
  const home = useRef(null);

  const scrollTo = (el) => {
    el.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  return (
    <Layout>
      <SEO title="Home" />
      <Header
        onRef={home}
        scrollToPlatform={() => scrollTo(platform)}
        scrollToFeatures={() => scrollTo(features)}
        scrollToProcess={() => scrollTo(process)}
        handleModal={handleModal}
      />
      <FirstBlock showModal={showModal} handleModal={handleModal}/>
      <SecondBlock onRef={platform}/>
      <ThirdBlock onRef={features}/>
      <ForthBlock onRef={process}/>
      <Footer
        scrollToHome={()=> scrollTo(home)}
        scrollToPlatform={() => scrollTo(platform)}
        scrollToFeatures={() => scrollTo(features)}
        scrollToProcess={() => scrollTo(process)}
      />
    </Layout>
  );
};

export default IndexPage;
