import React, { useRef } from 'react';
import Modal from 'react-modal';
import styles from '../styles/shedule-demo.module.css'
import Cross from '../assets/icons/cross.svg'


const modalStyles = {
  width: '710px',
  height: '478px',
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  background: '#FFFFFF',
  border: '0px',
  borderRadius: '0px',
}

const modalMobileStyles = {
  padding: '0',
  width: '335px',
  height: '557px',
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  background: '#FFFFFF',
  border: '0px',
  borderRadius: '0px',
}

const moadlOverlay = {
  background: 'rgba(28, 33, 93, 0.6)',
  zIndex: 100,
}

const ScheduleDemo = ({ showModal, handleCloseModal }) => {

  const moadlContent = (typeof window !== 'undefined' && window.innerWidth < 768) ? modalMobileStyles : modalStyles;

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);

  const onSubmit = (e) => {
    if (!(firstNameRef.current.value &&
        lastNameRef.current.value && 
        emailRef.current.value
      )) {
      e.preventDefault()
    }
    
  };

  return (
    <div>
      <Modal
        isOpen={showModal}
        style={{ overlay: moadlOverlay, content: moadlContent }}
        onRequestClose={handleCloseModal}
      >

        <div onClick={handleCloseModal} className={styles.crossIconContainer}>
          <Cross />
        </div>

        <div className={styles.title}>See a Clarity Value demo</div>

        <div className={styles.contentContainer}>
          <form
            method="post"
            name="schedule-demo"
            data-netlify="true"
            netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="schedule-demo" />

            <div className={styles.container}>
            <input
                ref={firstNameRef}
                type="text"
                name="first-name"
                placeholder="First Name*"
                className={styles.input}
              />
              <input
                ref={lastNameRef}
                type="text"
                name="last-name"
                placeholder="Last Name*"
                className={styles.input}
              />

            </div>

            <div className={styles.container}>
              <input
                ref={emailRef}
                type="tel"
                name="email"
                placeholder="Email*"
                className={styles.input}
              />

              <input
                type="text"
                name="phone-number"
                placeholder="Phone Number"
                className={styles.input}
              />
            </div>

            <div className={styles.container}>
              <input
                type="text"
                name="organization"
                placeholder="Organization"
                className={styles.input}
              />

              <input
                type="text"
                name="job-title"
                placeholder="Job Title"
                className={styles.input}
              />
            </div>

            <div className={styles.container}>
              <textarea
                name="message"
                placeholder="Tell us about your needs"
                className={styles.inputMessage}
              />
            </div>

            <div className={styles.container}>
              <button type="submit" onClick={onSubmit} className={styles.button}>
                <span>
                  Submit
                </span>
              </button>
            </div>

          </form>
          
        </div>
      </Modal>
    </div>
  );
}

export default ScheduleDemo;
